import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ErrorBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  position: 'relative',
  minWidth: '650px',
  width: '650px',
  height: '460px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minWidth: '100%',
  },
}));
