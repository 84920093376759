import { Box, Button, Typography } from '@mui/material';
import Image from 'next/image';
import { ErrorBoxWrapper, ImageWrapper } from './styles';
import useTheme from '@/theme/useTheme';

interface ErrorBoxProps {
  title: string;
  desc: string;
  src: string;
}

export default function ErrorBox({ title, desc, src }: ErrorBoxProps) {
  const { theme } = useTheme();

  return (
    <ErrorBoxWrapper>
      <Typography
        variant={'body2'}
        sx={{
          fontSize: '24px',
          transform: 'translateY(130px)',
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        }}
      >
        {desc}
      </Typography>
      <ImageWrapper>
        <Image fill alt={title} src={src} />
      </ImageWrapper>

      <Typography
        variant={'body2'}
        sx={{
          fontSize: '34px',
          transform: 'translateY(-88px)',
          fontWeight: '500',
          [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
          },
        }}
      >
        {title}
      </Typography>
    </ErrorBoxWrapper>
  );
}
