import LocalizedLink from '@/components/ui/LocalizedLink';
import { Box, Button, Typography } from '@mui/material';
import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import { getStaticAsset } from '@/utils/staticAsset';
import ErrorBox from '@/components/system/ErrorBox';
// export const runtime = 'experimental-edge';

const Custom404: NextPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  useEffect(() => {
    setHasPreviousPage(window.history.length > 2);
  }, []);

  const handleGoBack = async () => {
    if (hasPreviousPage) {
      router.back();
    } else {
      await router.push('/');
    }
  };

  return (
    <>
      <ErrorBox
        title={t('Page not found')}
        desc={t('Look like you are lost in space!')}
        src={getStaticAsset('/assets/images/error/404-bg.png')}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mr: 2,
        }}
      >
        <Button
          variant={'plain'}
          onClick={handleGoBack}
          sx={{
            mr: 3,
          }}
        >
          {t('Back to previous page')}
        </Button>
        <Button href={'/'} variant="contained">
          {t('HomePage')}
        </Button>
      </Box>
    </>
  );
};

export default Custom404;
